import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user: null,
		plants: [],
		materials: [],
		unitOfMeasurement: 'CS'
	},
	mutations: {
		SET_USER(state, user) {
			state.user = user;
		},
		SET_UNIT_OF_MEASUREMENT(state, uom) {
			state.unitOfMeasurement = uom
		},
		SET_PLANTS(state, plants) {
			state.plants = plants
		},
		SET_MATERIALS(state, materials) {
			state.materials = materials
		},
	},
	actions: {
		setUser({ commit }, user) {
			commit('SET_USER', user)
		},
		setUnitOfMeasurement({ commit }, uom) {
			commit('SET_UNIT_OF_MEASUREMENT', uom)
		},
		makeLookups: function ({ dispatch }) {
			var lastRefresh =
				new Date().getTime() - Number(localStorage.getItem("lf"));
			var storeExists = JSON.parse(localStorage.getItem("vuex")) || {};
			var old = lastRefresh > 172800000;

			(old || !storeExists.plants || !storeExists.plants.length) && dispatch("fetchPlants");
			(old || !storeExists.materials || !storeExists.materials.length) && dispatch("fetchMaterials");

			old && localStorage.setItem("lf", new Date().getTime());
		},
		async fetchPlants({ commit }) {
			await axios
				.get(
					`/api/cosmosData?search_type=plants&plant_search=1`
				)
				.then((res) => {
					commit("SET_PLANTS", res.data)
				})
				.catch((err) => console.log(err));
		},
		async fetchMaterials({ commit }) {
			await axios
				.get(
					`/api/cosmosData?search_type=items&material_search=1`
				)
				.then((res) => {
					commit("SET_MATERIALS", res.data)
				})
				.catch((err) => console.log(err));
		},
	},
	getters: {
		user: state => state.user,
		plants: state => state.plants,
		materials: state => state.materials,
		uom: state => state.unitOfMeasurement
	},
	modules: {},
	plugins: [createPersistedState()]
})
