<template>
	<div>
		<v-card flat>
			<template v-if="!loading">
				<div
					class="d-flex justify-space-between align-sm-center flex-column flex-sm-row align-start"
				>
					<div>
						<v-card-title :class="subtitle ? 'pb-3' : 'pb-0'">
							<h2 class="text-h5 ml-2">{{ title }}</h2>
						</v-card-title>
						<v-card-subtitle class="pb-0" v-if="subtitle">
							<span class="ml-2">{{ subtitle }}</span>
						</v-card-subtitle>
					</div>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<span
								v-on="on"
								class="handle align-self-end align-self-sm-center grabbable"
								style="padding-right: 27px; padding-top: 16px"
							>
								<v-icon>mdi-format-align-justify</v-icon>
							</span>
						</template>
						<span> Click And Drag To Reorder </span>
					</v-tooltip>
				</div>
			</template>
			<v-card-text>
				<div v-if="loading">
					<v-skeleton-loader
						class="mb-2"
						type="card-heading"
					></v-skeleton-loader>
				</div>
				<div :ref="`swiper-${num}`" class="swiper">
					<div class="swiper-wrapper">
						<template v-if="!loading">
							<template v-for="(m, i) in filterStock(stock)">
								<div
									class="swiper-slide"
									v-if="m.material_id"
									:key="m.id"
								>
									<v-card
										:class="{
											outOfStock:
												m.confirmedQuantity < 1 &&
												m.brand_owner_id == 1,
											htrOutOfStock:
												m.confirmedQuantity < 1 &&
												m.brand_owner_id == 0,
											htr: m.brand_owner_id == 0,
										}"
										class="ma-2"
										flat
										outlined
										style="
											min-height: 270px;
											height: 320px;
											max-height: 320px;
										"
									>
										<v-img
											:src="
												m.image_description
													? `https://stdzafeunorthbarkeepdev.blob.core.windows.net/barkeep-items-images/${m.image_description}`
													: 'https://stdzafeunorthbarkeepdev.blob.core.windows.net/barkeep-items-images/no-image-bottle.jpg'
											"
											contain
											height="150px"
											style="background-color: white"
											class="pt-1"
										>
											<div>
												<template>
													<span class="pl-2">
														{{ i + 1 }}
													</span>
													<v-tooltip bottom>
														<template
															v-slot:activator="{
																on,
															}"
														>
															<v-btn
																small
																icon
																v-on="on"
																class="mr-2 float-right"
																@click="
																	searchMaterial(
																		m,
																		uomNum[
																			uom
																		]
																	)
																"
															>
																<v-icon small>
																	mdi-magnify
																</v-icon>
															</v-btn>
														</template>
														<span
															>Search For This
															Material</span
														>
													</v-tooltip>
												</template>
												<span
													class="pl-2"
													v-if="m.brand_owner_id == 0"
												>
													<v-tooltip bottom>
														<template
															v-slot:activator="{
																on,
															}"
														>
															<v-img
																src="@/assets/htr.svg"
																width="20"
																contain
																style="
																	background-color: white;
																"
																class="pl-2"
																v-on="on"
															/>
														</template>
														<span>
															Henry Tayler & Ries
														</span>
													</v-tooltip>
												</span>
											</div>
										</v-img>

										<v-card-title
											class="text-body-1 font-weight-medium"
											style="
												word-break: break-word;
												font-size: 1em !important;
											"
										>
											{{ m.material_description }}
										</v-card-title>
										<v-card-text
											class="pa-4"
											style="
												position: absolute;
												bottom: 0px;
											"
										>
											<p class="mb-0 text-caption">
												<strong>Price:</strong>
												{{
													formatPrice(
														m.price_per_case_incl_vat
													)
												}}
												<v-tooltip bottom>
													<template
														v-slot:activator="{
															on,
														}"
													>
														<v-icon small v-on="on">
															mdi-help-circle-outline
														</v-icon>
													</template>
													<span>
														<span>
															Price per Case.
														</span>
														<br />
														<span>
															Incl. Bot, Dep and
															VAT
														</span>
													</span>
												</v-tooltip>
											</p>
											<p class="mb-0 text-caption">
												<strong>SKU:</strong>
												{{
													m.material_id.slice(
														m.material_id.length - 7
													)
												}}.{{ uomNum[uom] }}
												<v-tooltip bottom>
													<template
														v-slot:activator="{
															on,
														}"
													>
														<v-btn
															@click="
																copySKU(
																	m.material_id,
																	uomNum[uom]
																)
															"
															x-small
															icon
															v-on="on"
														>
															<v-icon small>
																mdi-content-copy
															</v-icon>
														</v-btn>
													</template>
													<span>Copy SKU</span>
												</v-tooltip>
											</p>
											<p class="mb-0 text-caption">
												<strong>Available:</strong>
												<span v-if="!stockLoading">
													{{
														fixStock(
															m.confirmedQuantity
														)
													}}
												</span>
												<span v-else>
													&nbsp;
													<v-progress-circular
														:size="15"
														:width="1"
														indeterminate
														color="primary"
													></v-progress-circular>
												</span>
											</p>
										</v-card-text>
									</v-card>
								</div>
							</template>
						</template>
						<template v-else>
							<div
								class="swiper-slide"
								v-for="i in 7"
								:key="i + 'i'"
							>
								<v-card flat outlined height="320">
									<v-skeleton-loader
										class="mx-auto"
										type="card"
										height="320"
									></v-skeleton-loader>
								</v-card>
							</div>
						</template>
					</div>
				</div>
				<div
					:class="`swiper-button-prev swiper-button-prev-${num}`"
				></div>
				<div
					:class="`swiper-button-next swiper-button-next-${num}`"
				></div>
				<template v-if="!stock.length && !loading">
					<v-card width="100%" flat class="ml-2">
						<span class="grey--text lighten-3">
							Click request button to receive recommendations
						</span>
					</v-card>
				</template>
			</v-card-text>
		</v-card>
		<v-snackbar v-model="snackbar" color="success" timeout="2000">
			SKU copied
			<template v-slot:action="{ attrs }">
				<v-btn icon v-bind="attrs" @click="snackbar = false">
					<v-icon>mdi-close-circle-outline</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";

import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
	name: "StockCards",
	props: {
		title: { required: true },
		subtitle: { required: false },
		uom: { required: true },
		stock: { required: true },
		loading: { required: true },
		stockLoading: { required: false },
		num: { required: true },
	},
	data() {
		return {
			snackbar: false,
			uomNum: {
				CS: 0,
				PC: 1,
				PAC: 2,
				PAL: 3,
			},
		};
	},
	mounted() {
		new Swiper(this.$refs[`swiper-${this.num}`], {
			modules: [Navigation, Pagination],
			slidesPerView: 1,
			slidesPerGroup: 1,
			spaceBetween: 20,
			breakpoints: {
				500: {
					slidesPerView: 2,
					slidesPerGroup: 2,
				},
				750: {
					slidesPerView: 3,
					slidesPerGroup: 3,
				},
				900: {
					slidesPerView: 4,
					slidesPerGroup: 4,
				},
				1150: {
					slidesPerView: 5,
					slidesPerGroup: 5,
				},
				1400: {
					slidesPerView: 6,
					slidesPerGroup: 6,
				},
				1800: {
					slidesPerView: 7,
					slidesPerGroup: 7,
				},
			},

			navigation: {
				nextEl: `.swiper-button-next-${this.num}`,
				prevEl: `.swiper-button-prev-${this.num}`,
			},
		});
	},
	methods: {
		formatPrice(price) {
			let formatter = new Intl.NumberFormat("en-ZA", {
				style: "currency",
				currency: "ZAR",
			});
			return formatter.format(price || 0);
		},
		searchMaterial(material, uom) {
			let sku =
				material.material_id.slice(material.material_id.length - 7) +
				"." +
				uom;
			this.$eventBus.$emit("search-material", {
				material: material,
				carousel: this.num,
				sku: sku,
			});
		},
		fixStock(stock) {
			return parseInt(stock ?? 0);
		},
		filterStock(stock) {
			let nums = cloneDeep(stock);
			return nums.sort((a, b) => {
				return (
					(parseInt(a.confirmedQuantity ?? 0) === 0) -
					(parseInt(b.confirmedQuantity ?? 0) === 0)
				);
			});
		},
		copySKU(SKU, uom) {
			let x = SKU.slice(SKU.length - 7) + "." + uom;
			navigator.clipboard.writeText(x);
			this.snackbar = true;
			this.debounchCopySKU(x, this.num);
		},
		debounchCopySKU: debounce(
			async function (x, n) {
				this.$eventBus.$emit("copied-sku", {
					sku: x,
					carousel: n,
				});
			},
			1000,
			{
				leading: true,
				trailing: false,
			}
		),
	},
};
</script>

<style>
.htr {
	border-color: #c0b484 !important;
	background-color: #c0b484 !important;
}
.outOfStock {
	border-color: #ffa99e !important;
	background-color: #ffa99e !important;
}
.htrOutOfStock {
	border-color: #c0b484 !important;
	background-color: #ffa99e !important;
}
.swiper-button-next {
	background: rgba(77, 77, 79, 0.8);
	border-radius: 50%;
	height: 2.5rem;
	width: 2.5rem;
	color: white;
}
.swiper-button-prev {
	background: rgba(77, 77, 79, 0.8);
	border-radius: 50%;
	height: 2.5rem;
	width: 2.5rem;
	color: white;
}
:root {
	--swiper-navigation-size: 1.5rem;
}
.grabbable {
	cursor: move; /* fallback if grab cursor is unsupported */
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
}
</style>
