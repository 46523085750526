var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"flat":""}},[(!_vm.loading)?[_c('div',{staticClass:"d-flex justify-space-between align-sm-center flex-column flex-sm-row align-start"},[_c('div',[_c('v-card-title',{class:_vm.subtitle ? 'pb-3' : 'pb-0'},[_c('h2',{staticClass:"text-h5 ml-2"},[_vm._v(_vm._s(_vm.title))])]),(_vm.subtitle)?_c('v-card-subtitle',{staticClass:"pb-0"},[_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.subtitle))])]):_vm._e()],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticClass:"handle align-self-end align-self-sm-center grabbable",staticStyle:{"padding-right":"27px","padding-top":"16px"}},on),[_c('v-icon',[_vm._v("mdi-format-align-justify")])],1)]}}],null,false,2207909808)},[_c('span',[_vm._v(" Click And Drag To Reorder ")])])],1)]:_vm._e(),_c('v-card-text',[(_vm.loading)?_c('div',[_c('v-skeleton-loader',{staticClass:"mb-2",attrs:{"type":"card-heading"}})],1):_vm._e(),_c('div',{ref:`swiper-${_vm.num}`,staticClass:"swiper"},[_c('div',{staticClass:"swiper-wrapper"},[(!_vm.loading)?[_vm._l((_vm.filterStock(_vm.stock)),function(m,i){return [(m.material_id)?_c('div',{key:m.id,staticClass:"swiper-slide"},[_c('v-card',{staticClass:"ma-2",class:{
										outOfStock:
											m.confirmedQuantity < 1 &&
											m.brand_owner_id == 1,
										htrOutOfStock:
											m.confirmedQuantity < 1 &&
											m.brand_owner_id == 0,
										htr: m.brand_owner_id == 0,
									},staticStyle:{"min-height":"270px","height":"320px","max-height":"320px"},attrs:{"flat":"","outlined":""}},[_c('v-img',{staticClass:"pt-1",staticStyle:{"background-color":"white"},attrs:{"src":m.image_description
												? `https://stdzafeunorthbarkeepdev.blob.core.windows.net/barkeep-items-images/${m.image_description}`
												: 'https://stdzafeunorthbarkeepdev.blob.core.windows.net/barkeep-items-images/no-image-bottle.jpg',"contain":"","height":"150px"}},[_c('div',[[_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(i + 1)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({
															on,
														}){return [_c('v-btn',_vm._g({staticClass:"mr-2 float-right",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.searchMaterial(
																	m,
																	_vm.uomNum[
																		_vm.uom
																	]
																)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-magnify ")])],1)]}}],null,true)},[_c('span',[_vm._v("Search For This Material")])])],(m.brand_owner_id == 0)?_c('span',{staticClass:"pl-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({
															on,
														}){return [_c('v-img',_vm._g({staticClass:"pl-2",staticStyle:{"background-color":"white"},attrs:{"src":require("@/assets/htr.svg"),"width":"20","contain":""}},on))]}}],null,true)},[_c('span',[_vm._v(" Henry Tayler & Ries ")])])],1):_vm._e()],2)]),_c('v-card-title',{staticClass:"text-body-1 font-weight-medium",staticStyle:{"word-break":"break-word","font-size":"1em !important"}},[_vm._v(" "+_vm._s(m.material_description)+" ")]),_c('v-card-text',{staticClass:"pa-4",staticStyle:{"position":"absolute","bottom":"0px"}},[_c('p',{staticClass:"mb-0 text-caption"},[_c('strong',[_vm._v("Price:")]),_vm._v(" "+_vm._s(_vm.formatPrice( m.price_per_case_incl_vat ))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({
														on,
													}){return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('span',[_c('span',[_vm._v(" Price per Case. ")]),_c('br'),_c('span',[_vm._v(" Incl. Bot, Dep and VAT ")])])])],1),_c('p',{staticClass:"mb-0 text-caption"},[_c('strong',[_vm._v("SKU:")]),_vm._v(" "+_vm._s(m.material_id.slice( m.material_id.length - 7 ))+"."+_vm._s(_vm.uomNum[_vm.uom])+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({
														on,
													}){return [_c('v-btn',_vm._g({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.copySKU(
																m.material_id,
																_vm.uomNum[_vm.uom]
															)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy SKU")])])],1),_c('p',{staticClass:"mb-0 text-caption"},[_c('strong',[_vm._v("Available:")]),(!_vm.stockLoading)?_c('span',[_vm._v(" "+_vm._s(_vm.fixStock( m.confirmedQuantity ))+" ")]):_c('span',[_vm._v("   "),_c('v-progress-circular',{attrs:{"size":15,"width":1,"indeterminate":"","color":"primary"}})],1)])])],1)],1):_vm._e()]})]:_vm._l((7),function(i){return _c('div',{key:i + 'i',staticClass:"swiper-slide"},[_c('v-card',{attrs:{"flat":"","outlined":"","height":"320"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"card","height":"320"}})],1)],1)})],2)]),_c('div',{class:`swiper-button-prev swiper-button-prev-${_vm.num}`}),_c('div',{class:`swiper-button-next swiper-button-next-${_vm.num}`}),(!_vm.stock.length && !_vm.loading)?[_c('v-card',{staticClass:"ml-2",attrs:{"width":"100%","flat":""}},[_c('span',{staticClass:"grey--text lighten-3"},[_vm._v(" Click request button to receive recommendations ")])])]:_vm._e()],2)],2),_c('v-snackbar',{attrs:{"color":"success","timeout":"2000"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" SKU copied ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }