var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card',{staticClass:"pa-2",attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":_vm.similarBrand[0] && _vm.showCards && !_vm.loading
								? 8
								: 12,"lg":_vm.similarBrand[0] && _vm.showCards && !_vm.loading
								? 9
								: 12}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"lg":"6","cols":"12","id":"tourCustomer"}},[_c('v-autocomplete',{attrs:{"label":"Customer","items":_vm.customers,"item-text":"display","item-value":"customer_id","dense":"","outlined":"","search-input":_vm.searchCustomers,"no-data-text":_vm.noCustomerData,"clearable":"","messages":_vm.creditSearch},on:{"update:searchInput":function($event){_vm.searchCustomers=$event},"update:search-input":function($event){_vm.searchCustomers=$event},"change":function($event){return _vm.updatePlantAndCreditInfo()}},scopedSlots:_vm._u([{key:"message",fn:function({ message }){return [(_vm.searchingCredit)?_c('span',[_c('strong',[_vm._v(" Credit Available: ")]),_c('span',[_vm._v("   "),_c('v-progress-circular',{attrs:{"size":10,"width":1,"indeterminate":"","color":"primary"}})],1)]):[_c('span',{style:({
													color:
														message.includes(
															'Blocked'
														) ||
														message.includes(
															'-'
														)
															? 'red'
															: 'green',
												}),domProps:{"innerHTML":_vm._s(message)}}),_c('span',[_c('v-menu',{attrs:{"open-on-hover":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({
															on,
															attrs,
														}){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[(_vm.creditInfo)?_c('v-list',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Last Payment Amount: ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.creditInfo.LastPaymentAmount || 0)+" ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Last Payment Date: ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.creditInfo.LastPaymentDate || "None")+" ")])],1)],1)],1):_vm._e()],1)],1)]]}}]),model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}})],1),_c('v-col',{staticClass:"d-flex pb-0",attrs:{"lg":"6","cols":"12","id":"tourMaterial"}},[_c('v-autocomplete',{attrs:{"label":"Material","items":_vm.materials,"item-text":"display","return-object":"","dense":"","outlined":"","search-input":_vm.searchMaterials,"no-data-text":_vm.noMaterialData,"cache-items":"","clearable":"","messages":_vm.stockSearch},on:{"update:searchInput":function($event){_vm.searchMaterials=$event},"update:search-input":function($event){_vm.searchMaterials=$event},"change":function($event){_vm.stockSearch = null}},scopedSlots:_vm._u([{key:"message",fn:function({ message }){return [(_vm.searchingStock)?_c('span',[_c('strong',[_vm._v(" Stock Available: ")]),_c('span',[_vm._v("   "),_c('v-progress-circular',{attrs:{"size":10,"width":1,"indeterminate":"","color":"primary"}})],1)]):_c('span',{style:({
												color: _vm.getCreditColor(
													message
												),
											})},[_vm._v(" Stock Available: "+_vm._s(message)+" ")])]}}]),model:{value:(_vm.material),callback:function ($$v) {_vm.material=$$v},expression:"material"}}),_c('v-tooltip',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"ml-2",staticStyle:{"height":"40px"},attrs:{"color":"primary","loading":_vm.searchingStock,"disabled":!_vm.uom ||
												!_vm.plant ||
												!_vm.customer ||
												!_vm.material?.material_id ||
												_vm.searchingStock,"id":"tourStockSearch"},on:{"click":function($event){return _vm.searchStock(_vm.material)}}},on),[_c('v-icon',[_vm._v("mdi-autorenew")])],1)]}}])},[_c('span',[_vm._v("Search Stock")])])],1),_c('v-col',{staticClass:"pb-0",attrs:{"lg":"6","md":"6","sm":"7","cols":"12","id":"tourPlant"}},[_c('v-autocomplete',{attrs:{"label":"Plant","items":_vm.plants,"item-text":"display","item-value":"plant_id","dense":"","outlined":""},model:{value:(_vm.plant),callback:function ($$v) {_vm.plant=$$v},expression:"plant"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"lg":"3","md":"6","sm":"5","cols":"12","id":"tourUOM"}},[_c('v-select',{attrs:{"value":_vm.uom,"items":_vm.uoms,"item-text":"display","item-value":"unit","label":"Unit of Measurement","outlined":"","dense":""},on:{"change":function($event){return _vm.changeUoM($event)}}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"lg":"3","cols":"12"}},[_c('v-tooltip',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.plant ||
												!_vm.customer ||
												_vm.loading ||
												_vm.stockLoading,"color":"primary","block":"","id":"tourRecommendations","loading":_vm.loading || _vm.stockLoading},on:{"click":function($event){return _vm.getRecommendations()}}},on),[_vm._v(" Recommendations ")])]}}])},[_c('span',[_vm._v("Get Recommendations")])])],1)],1)],1),(_vm.similarBrand[0] && _vm.showCards && !_vm.loading)?_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"3"}},[_c('v-card',{staticClass:"my-2 mx-1",class:{
								outOfStock:
									_vm.similarBrand[0].confirmedQuantity < 1 &&
									_vm.similarBrand[0].brand_owner_id == 1,
								htrOutOfStock:
									_vm.similarBrand[0].confirmedQuantity < 1 &&
									_vm.similarBrand[0].brand_owner_id == 0,
								htr: _vm.similarBrand[0].brand_owner_id == 0,
							},attrs:{"flat":"","outlined":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","lg":"4"}},[_c('v-img',{staticClass:"pt-1",staticStyle:{"background-color":"white"},attrs:{"src":_vm.similarBrand[0]
												.image_description
												? `https://stdzafeunorthbarkeepdev.blob.core.windows.net/barkeep-items-images/${_vm.similarBrand[0].image_description}`
												: 'https://stdzafeunorthbarkeepdev.blob.core.windows.net/barkeep-items-images/no-image-bottle.jpg',"contain":"","height":"150px","width":"150px"}},[(
												_vm.similarBrand[0]
													.brand_owner_id == 0
											)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({
														on,
													}){return [_c('v-img',_vm._g({staticClass:"ma-2",staticStyle:{"background-color":"white"},attrs:{"src":require("@/assets/htr.svg"),"width":"20","contain":""}},on))]}}],null,false,1772529811)},[_c('span',[_vm._v(" Henry Tayler & Ries ")])])],1):_vm._e()])],1),_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('v-card-title',{staticClass:"text-body-1 font-weight-medium",staticStyle:{"word-break":"break-word","font-size":"0.7em !important"}},[_vm._v(" "+_vm._s(_vm.similarBrand[0] .material_description)+" ")]),_c('v-card-text',{staticClass:"pa-4",staticStyle:{"bottom":"0px"}},[_c('p',{staticClass:"mb-0 text-caption",staticStyle:{"word-break":"break-word","font-size":"0.75em !important"}},[_c('strong',[_vm._v("Price:")]),_vm._v(" "+_vm._s(_vm.formatCredits( _vm.similarBrand[0] .price_per_case_incl_vat ))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({
														on,
													}){return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,3291480604)},[_c('span',[_c('span',[_vm._v(" Price per Case. ")]),_c('br'),_c('span',[_vm._v(" Incl. Bot, Dep and VAT ")])])])],1),_c('p',{staticClass:"mb-0 text-caption",staticStyle:{"word-break":"break-word","font-size":"0.75em !important"}},[_c('strong',[_vm._v("SKU:")]),_vm._v(" "+_vm._s(_vm.similarBrand[0].material_id.slice( _vm.similarBrand[0] .material_id .length - 7 ))+"."+_vm._s(_vm.uomNum[_vm.searchInfo.uom])+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({
														on,
													}){return [_c('v-btn',_vm._g({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.copySKU(
																_vm.similarBrand[0]
																	.material_id,
																_vm.uomNum[
																	_vm.searchInfo
																		.uom
																]
															)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)]}}],null,false,3327908589)},[_c('span',[_vm._v("Copy SKU")])])],1),_c('p',{staticClass:"mb-0 text-caption",staticStyle:{"word-break":"break-word","font-size":"0.75em !important"}},[_c('strong',[_vm._v("Available:")]),(!_vm.stockLoading)?_c('span',[_vm._v(" "+_vm._s(_vm.formatStock( _vm.similarBrand[0] .confirmedQuantity ))+" ")]):_c('span',[_vm._v("   "),_c('v-progress-circular',{attrs:{"size":15,"width":1,"indeterminate":"","color":"primary"}})],1)])])],1)],1)],1)],1):_vm._e()],1)],1),_c('v-divider',{staticStyle:{"width":"100%"},style:(_vm.$vuetify.breakpoint.mdAndDown && 'margin-top: 10px')})],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[(_vm.showCards)?[_c('draggable',{staticClass:"list-group",attrs:{"tag":"div","list":_vm.stockCards,"handle":".handle"},on:{"change":_vm.stockCardsReorder}},_vm._l((_vm.stockCards.filter(
								(e) => _vm.$data[e.stock].length
							)),function(e,idx){return _c('div',{key:idx},[_c('StockCards',{attrs:{"title":_vm.createTitle(e.title),"subtitle":_vm.createSubtitle(e.subtitle),"uom":_vm.searchInfo.uom,"stock":_vm.$data[e.stock],"loading":_vm.loading,"stockLoading":_vm.stockLoading,"num":idx}})],1)}),0)]:[_c('v-card',{staticClass:"mx-auto text-center",attrs:{"width":"100%","flat":""}},[_c('span',{staticClass:"text-h5 grey--text lighten-3"},[_vm._v(" Enter search criteria to receive recommendations. ")])])]],2)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"timeout":"2000"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }