import store from '../store'
import { PublicClientApplication } from "@azure/msal-browser";

let msal = null

export async function initialize() {
	const msalConfig = {
		auth: {
			// 'Application (client) ID' of app registration in Azure portal - this value is a GUID
			clientId: "fd244f55-fa46-4550-98d8-c2f117754b18",
			// Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
			authority:
				"https://login.microsoftonline.com/66e853de-ece3-44dd-9d66-ee6bdf4159d4",
			// Full redirect URL, in form of http://localhost:3000
			redirectUri: "https://qa.barkeep.distell.co.za/login",
		},
		cache: {
			cacheLocation: "localStorage", // This configures where your cache will be stored
			storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
		},
	};
	msal = await new PublicClientApplication(msalConfig);
}


export async function signIn() {
	await msal
		.loginPopup({})
		.then(() => {
			const myAccounts = msal.getAllAccounts();
			store.dispatch("setUser", {
				name: myAccounts[0].name,
				username: myAccounts[0].username,
			});
		})
		.catch((error) => {
			console.error(`error during authentication: ${error}`);
		});
}

export function signOut() {
	store.dispatch('setUser', null)
	if (!msal) return;
	msal.logoutRedirect({
		onRedirectNavigate: () => {
			return false;
		}
	});
}

export function isAuthenticated() {
	if (!msal) return false
	const accounts = msal.getAllAccounts();
	return accounts.length > 0
}

export function getUser() {
	if (!msal) return null
	const accounts = msal.getAllAccounts();
	if (accounts.length == 0) {
		return null;
	}
	return accounts[0];
}