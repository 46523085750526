import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { initialize } from './auth'

import { ValidationProvider, ValidationObserver } from 'vee-validate';

Vue.prototype.$eventBus = new Vue();

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);

Vue.config.productionTip = false

initialize().then(() => {
	new Vue({
		router,
		store,
		vuetify,
		render: h => h(App)
	}).$mount('#app')
})


