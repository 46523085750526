<template>
	<v-container fluid>
		<v-card>
			<v-card class="pa-2" flat>
				<v-card-title>
					<v-row style="width: 100%">
						<v-col cols="12" lg="5" md="12" sm="12" class="pb-0">
							<v-autocomplete
								v-model="material"
								label="Planning Material"
								:items="filteredMaterials"
								item-text="display"
								:search-input.sync="searchMaterials"
								:no-data-text="noMaterialData"
								return-object
								dense
								outlined
								clearable
								multiple
								chips
								@change="checkMaterialLength()"
							>
								<template v-slot:selection="data">
									<v-chip
										v-bind="data.attrs"
										:input-value="data.selected"
										close
										@click="data.select"
										@click:close="
											removeChip(material, data.item)
										"
										small
										class="ma-1"
										v-if="data.index < 5"
									>
										{{ data.item.material_description }}
									</v-chip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<span
												v-on="on"
												v-if="data.index === 5"
												class="grey--text text-caption"
											>
												(+{{ material.length - 5 }}
												others)
											</span>
										</template>
										<span>{{ materialText }}</span>
									</v-tooltip>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" lg="5" md="9" sm="12" class="pb-0">
							<v-autocomplete
								v-model="nearestPlants"
								label="Plant"
								:items="filteredPlants"
								item-text="display"
								item-value="plant_id"
								dense
								outlined
								return-object
								chips
								multiple
								clearable
								@change="checkPlantLength()"
							>
								<template v-slot:prepend-item>
									<v-list-item
										@mousedown.prevent
										@click="toggle"
									>
										<v-list-item-action>
											<v-icon>
												{{ icon }}
											</v-icon>
										</v-list-item-action>
										<v-list-item-content>
											<v-list-item-title>
												Select All
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-divider class="mt-2"></v-divider>
								</template>
								<template v-slot:selection="data">
									<v-chip
										v-bind="data.attrs"
										:input-value="data.selected"
										close
										@click="data.select"
										@click:close="
											removeChip(nearestPlants, data.item)
										"
										small
										class="ma-1"
										v-if="data.index < 5"
									>
										{{ data.item.plant_description }}
									</v-chip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<span
												v-on="on"
												v-if="data.index === 5"
												class="grey--text text-caption"
											>
												(+{{ nearestPlants.length - 5 }}
												others)
											</span>
										</template>
										<span>{{ plantText }}</span>
									</v-tooltip>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" lg="2" md="3" sm="6" class="pb-0">
							<v-select
								:value="uom"
								:items="uoms"
								item-text="display"
								item-value="unit"
								label="Unit of Measurement"
								outlined
								dense
								hide-details
								@change="changeUoM($event)"
							></v-select>
						</v-col>
						<v-col cols="12" lg="3" md="4" sm="6" class="pb-0">
							<v-autocomplete
								v-model="statsGroupFilter"
								:items="statsGroups"
								item-text="stats_group_description"
								item-value="stats_group_description"
								label="Stats Group Filter"
								outlined
								dense
								chips
								multiple
								clearable
								hide-details
							>
								<template v-slot:selection="data">
									<v-chip
										v-bind="data.attrs"
										:input-value="data.selected"
										close
										@click="data.select"
										@click:close="
											removeChip(
												statsGroupFilter,
												data.item
											)
										"
										small
									>
										{{ data.item }}
									</v-chip>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" lg="3" md="4" sm="6" class="pb-0">
							<v-autocomplete
								v-model="brandFilter"
								:items="brands"
								item-text="brand_description"
								item-value="brand_description"
								label="Brand Filter"
								outlined
								dense
								clearable
								hide-details
								multiple
								chips
							>
								<template v-slot:selection="data">
									<v-chip
										v-bind="data.attrs"
										:input-value="data.selected"
										close
										@click="data.select"
										@click:close="
											removeChip(brandFilter, data.item)
										"
										small
									>
										{{ data.item }}
									</v-chip>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" lg="3" md="4" sm="6" class="pb-0">
							<v-autocomplete
								v-model="plantFilter"
								:items="plantTypes"
								item-text="name2"
								item-value="name2"
								label="Plant Type Filter"
								outlined
								dense
								clearable
								hide-details
								multiple
								chips
							>
								<template v-slot:selection="data">
									<v-chip
										v-bind="data.attrs"
										:input-value="data.selected"
										close
										@click="data.select"
										@click:close="
											removeChip(plantFilter, data.item)
										"
										small
									>
										{{ data.item }}
									</v-chip>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" lg="3" md="12" sm="12" class="pb-0">
							<v-btn
								:disabled="
									!uom ||
									!material.length ||
									!nearestPlants.length
								"
								@click="getStockAtPlants()"
								depressed
								color="primary"
								block
								:loading="loading"
							>
								Request
							</v-btn>
						</v-col>
						<v-col cols="12"></v-col>
					</v-row>
				</v-card-title>
			</v-card>
			<v-divider
				style="width: 100%"
				:style="$vuetify.breakpoint.mdAndDown && 'margin-top: 10px'"
				v-if="stock.length"
			/>
			<template v-if="stock.length">
				<v-card flat>
					<v-card-title> Floor Availability: </v-card-title>
					<v-card-text>
						<v-data-table
							:loading="loading"
							:headers="floorHeaders"
							:items="stock"
							item-key="id"
						>
						</v-data-table>
					</v-card-text>
				</v-card>
				<v-card flat>
					<v-card-title> Available To Promise: </v-card-title>
					<v-card-text>
						<v-data-table
							:loading="loading"
							:headers="headers"
							:items="stock"
							item-key="id"
						>
						</v-data-table>
					</v-card-text>
				</v-card>
			</template>
		</v-card>
		<!-- <v-dialog
			v-model="dialog"
			no-click-animation
			retain-focus
			max-width="400px"
			persistent
		>
			<v-card>
				<v-card-title>
					<span class="text-h5">Add New Plant Set</span>
				</v-card-title>
				<v-card-text>
					<v-container class="pa-3">
						<v-text-field
							label="Plant Set Name"
							outlined
							v-model="newPlantSet.name"
							dense
						></v-text-field>
						<v-autocomplete
							v-model="newPlantSet.plants"
							label="Plant"
							:items="plants"
							:item-text="
								({ plant_description, plant_id }) =>
									`(${plant_id}) ${plant_description}`
							"
							item-value="plant_id"
							dense
							outlined
							return-object
							chips
							multiple
							hide-details
							clearable
							@change="checkPlantLength()"
						>
							<template v-slot:prepend-item>
								<v-list-item
									@mousedown.prevent
									@click="openPlantSetDialog"
								>
									<v-list-item-action> </v-list-item-action>
									<v-list-item-content>
										<v-list-item-title>
											Add New Plant Set
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-divider class="mt-2"></v-divider>
								<v-list-item @mousedown.prevent @click="toggle">
									<v-list-item-action>
										<v-icon>
											{{ icon }}
										</v-icon>
									</v-list-item-action>
									<v-list-item-content>
										<v-list-item-title>
											Select All
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-divider class="mt-2"></v-divider>
							</template>
							<template v-slot:selection="data">
								<v-chip
									v-bind="data.attrs"
									:input-value="data.selected"
									close
									@click="data.select"
									@click:close="
										removeChip(nearestPlants, data.item)
									"
									small
									class="ma-1"
									v-if="data.index < 5"
								>
									{{ data.item.plant_description }}
								</v-chip>
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<span
											v-on="on"
											v-if="data.index === 5"
											class="grey--text text-caption"
										>
											(+{{ nearestPlants.length - 5 }}
											others)
										</span>
									</template>
									<span>{{ plantText }}</span>
								</v-tooltip>
							</template>
						</v-autocomplete>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="savePlantSet()">
						Save
					</v-btn>
					<v-btn @click="dialog = false"> Cancel </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog> -->
	</v-container>
</template>

<script>
import { mapGetters } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import uniqBy from "lodash/uniqBy";
import filter from "lodash/filter";
import debounce from "lodash/debounce";

const axios = require("axios").default;

const MAX_COMBO = 180;

export default {
	data() {
		return {
			search: "",
			loading: false,
			plant: null,
			promises: [],
			nearestPlants: [],
			stock: [],
			rawStock: [],
			material: [],
			materials: [],
			searchMaterials: null,
			uoms: [
				{ unit: "CS", name: "Case", display: "(CS) Case" },
				{ unit: "PC", name: "Piece", display: "(PC) Piece" },
				{ unit: "PAC", name: "Pack", display: "(PAC) Pack" },
				{ unit: "PAL", name: "Pallet", display: "(PAL) Pallet" },
			],
			statsGroupFilter: [],
			brandFilter: [],
			plantFilter: [],
			headers: [],
			floorHeaders: [],
			dialog: false,
			newPlantSet: {
				name: "",
				plants: [],
			},
			plantSets: [],
		};
	},
	computed: {
		...mapGetters(["plants", "uom", "user"]),
		allPlantsSelected() {
			return (
				JSON.stringify(this.nearestPlants.length) ===
				JSON.stringify(this.filteredPlants.length)
			);
		},
		somePlantsSelected() {
			return this.nearestPlants.length > 0 && !this.allPlantsSelected;
		},
		icon() {
			if (this.allPlantsSelected) return "mdi-close-box";
			if (this.somePlantsSelected) return "mdi-minus-box";
			return "mdi-checkbox-blank-outline";
		},
		statsGroups() {
			let x = cloneDeep(this.materials);
			return x.map((e) => e.stats_group_description);
		},
		brands() {
			let x = cloneDeep(this.materials);
			return x.map((e) => e.brand_description);
		},
		plantTypes() {
			let x = cloneDeep(this.plants);
			return x.map((e) => e.name2);
		},
		filteredMaterials() {
			let x = cloneDeep(this.materials);
			if (this.statsGroupFilter.length)
				x = filter(x, (e) =>
					this.statsGroupFilter.includes(e.stats_group_description)
				);
			if (this.brandFilter.length)
				x = filter(x, (e) =>
					this.brandFilter.includes(e.brand_description)
				);
			if (this.material.length) {
				for (let i = 0; i < this.material.length; i++)
					if (!x.includes(this.material[i])) x.push(this.material[i]);
			}
			return x;
		},
		filteredPlants() {
			let x = cloneDeep(this.plants);
			if (this.plantFilter.length)
				x = filter(x, (e) => this.plantFilter.includes(e.name2));
			x = [...x, ...this.nearestPlants];
			return uniqBy(x, "plant_id");
		},

		materialText() {
			if (this.material.length < 6) return "";
			let x = this.material.map((e) => e.material_description);
			if (this.material.length == 6) return x[5];
			x = x.slice(5, -1).join(", ") + ", and " + x.slice(-1);
			return x;
		},

		plantText() {
			if (this.nearestPlants.length < 6) return "";
			let x = this.nearestPlants.map((e) => e.plant_description);
			if (this.nearestPlants.length == 6) return x[5];
			x = x.slice(5, -1).join(", ") + ", and " + x.slice(-1);
			return x;
		},
		noMaterialData() {
			if (!this.searchMaterials)
				return "Enter material name or ID to search";
			else {
				return this.materialsLoading
					? "Searching for materials"
					: "No data available";
			}
		},
	},
	methods: {
		deletePlantSet(index) {
			this.plantSets.splice(index, 1);
			this.newPlantSet = {
				name: "",
				plants: [],
			};
		},
		editPlantSet(plantSet) {
			this.newPlantSet = plantSet;
			this.dialog = true;
		},
		savePlantSet() {
			this.dialog = false;
			let x = this.plantSets.findIndex(
				(e) => e.name == this.newPlantSet.name
			);
			if (x < 0) this.plantSets.push(this.newPlantSet);
			else this.plantSets.splice(x, 1, this.newPlantSet);
			this.newPlantSet = {
				name: "",
				plants: [],
			};
		},
		openPlantSetDialog() {
			this.dialog = true;
			this.newPlantSet = {
				name: "",
				plants: [],
			};
		},
		changeUoM(ev) {
			this.$store.dispatch("setUnitOfMeasurement", ev);
		},
		toggle(plants = []) {
			this.$nextTick(() => {
				if (
					this.allPlantsSelected ||
					(plants.length &&
						JSON.stringify(this.nearestPlants) ==
							JSON.stringify(plants))
				) {
					this.nearestPlants = [];
				} else {
					if (plants.length) this.nearestPlants = cloneDeep(plants);
					else this.nearestPlants = cloneDeep(this.filteredPlants);
				}
			});
		},
		checkPlantLength() {
			let totalCombo = MAX_COMBO / this.material.length ?? 1;
			if (this.nearestPlants.length > totalCombo)
				this.nearestPlants.pop();
		},
		checkMaterialLength() {
			let totalCombo = MAX_COMBO / this.nearestPlants.length ?? 1;
			if (this.material.length > totalCombo) this.material.pop();
		},
		removeChip(collection, item) {
			const index = collection.findIndex(
				(e) => JSON.stringify(e) === JSON.stringify(item)
			);
			if (index >= 0) collection.splice(index, 1);
		},
		async addActivity() {
			await axios
				.post(`/api/stocksession`, {
					materials: this.material.map((e) => {
						return {
							material_id: e.material_id,
							material_description: e.material_description,
						};
					}),
					plants: this.nearestPlants.map((e) => {
						return {
							plant_description: e.plant_description,
							plant_id: e.plant_id,
						};
					}),
					uom: this.uom,
					user_email: this.user.username,
					date_added: new Date().toJSON(),
					activity_type: "stock_search",
				})
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
		},
		async getStockAtPlants() {
			this.loading = true;
			this.stock = [];
			this.promises = [];
			this.rawStock = [];
			let x = [];
			let y = [];
			for (let i = 0; i < this.nearestPlants.length; i++) {
				x.push({
					text: this.nearestPlants[i].plant_description,
					align: "end",
					value: `stockPlant` + (i + 1),
				});
				y.push({
					text: this.nearestPlants[i].plant_description,
					align: "end",
					value: `floorStockPlant` + (i + 1),
				});
			}
			this.headers = [
				{
					text: "Planning Material",
					align: "start",
					value: "material",
					width: "150px",
				},
				{
					text: `Total At Plant${
						this.nearestPlants.length > 1 ? "s" : ""
					}`,
					align: "end",
					value: "total",
					width: "100px",
				},
				...x,
			];
			this.floorHeaders = [
				{
					text: "Planning Material",
					align: "start",
					value: "material",
					width: "150px",
				},
				{
					text: `Total At Plant${
						this.nearestPlants.length > 1 ? "s" : ""
					}`,
					align: "end",
					value: "floorTotal",
					width: "100px",
				},
				...y,
			];
			for (let i = 0; i < this.nearestPlants.length; i++) {
				this.promises.push(
					axios
						.post(
							"/api/stock",
							{
								item_list: this.material.map(
									(e) => e.material_id
								),
								plant_id: this.nearestPlants[i].plant_id,
								uom_id: this.uom,
							},
							{ timeout: 200000 }
						)
						.then(async (res) => {
							this.rawStock.push({
								...res.data,
								index: i,
							});
						})
						.catch((err) => {
							Promise.reject(err);
						})
				);
			}
			Promise.all(this.promises)
				.then(() => {
					this.rawStock.sort((a, b) => {
						if (a.index < b.index) return -1;
						if (a.index > b.index) return 1;
						return 0;
					});
					for (let i = 0; i < this.material.length; i++) {
						let x = {};
						let total = 0;
						let floorTotal = 0;
						for (let j = 0; j < this.nearestPlants.length; j++) {
							let quantity = parseInt(
								this.rawStock[j][i].confirmedQuantity ?? 0
							);
							let floorQuantity = parseInt(
								this.rawStock[j][i].floorQuantity ?? 0
							);
							x["stockPlant" + (j + 1)] =
								quantity.toLocaleString();
							x["floorStockPlant" + (j + 1)] =
								floorQuantity.toLocaleString();
							total += quantity;
							floorTotal += floorQuantity;
						}
						this.stock.push({
							material: this.material[i].display,
							...x,
							total: total.toLocaleString(),
							floorTotal: floorTotal.toLocaleString(),
						});
					}
					this.addActivity();
					this.loading = false;
				})
				.catch((error) => {
					console.error(error);
				});
		},
		debounceMaterials: debounce(async function (search) {
			const sanitizedInput = search
				.trim()
				.toLowerCase()
				.replace(/&/g, "&amp;")
				.replace(/</g, "&lt;")
				.replace(/"/g, "&quot;");

			try {
				this.materialsLoading = true;
				const { data } = await axios.get(
					`/api/cosmosData?search_type=items&search=${sanitizedInput}`
				);
				this.materials = cloneDeep(data);
				this.materialsLoading = false;
			} catch (error) {
				this.materialsLoading = false;
				console.error(error);
			}
		}, 700),
		searchMaterial(m) {
			this.$eventBus.$emit("search-material", m);
		},
	},
	watch: {
		searchMaterials(v, o) {
			let x = null;
			if (this.material?.material_id)
				x = find(this.materials, {
					material_id: this.material.material_id,
				})?.display;
			if (v && v != o && v != x) {
				this.materialsLoading = true;
				this.debounceMaterials(v);
			}
		},
	},
};
</script>

<style>
.v-autocomplete__content .v-list__tile {
	height: auto;
}

th:nth-of-type(1),
td:nth-of-type(1) {
	border-left: 1px solid #dddddd;
}
td,
th {
	border-right: 1px solid #dddddd;
}
th {
	border-top: 1px solid #dddddd;
}

/* Makes the first column sticky. This is used to see what plant they're looking at */
table > tbody > tr > td:nth-child(-n + 2),
table > thead > tr > th:nth-child(-n + 2) {
	position: sticky !important;
	position: -webkit-sticky !important;
	left: 0;
	z-index: 5;
	background: white;
}

table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1) {
	left: 0;
}
table > tbody > tr > td:nth-child(2),
table > thead > tr > th:nth-child(2) {
	left: 150px;
}
</style>
