<template>
	<v-app>
		<Navigation />
		<v-main style="background-color: rgba(248, 248, 248, 1)">
			<router-view />
		</v-main>
		<Feedback />
	</v-app>
</template>

<script>
import Feedback from "@/components/Feedback.vue";
import Navigation from "@/components/Navigation.vue";

export default {
	name: "App",
	data: () => ({}),
	async created() {
		await this.$store.dispatch("makeLookups");
	},
	methods: {},
	components: {
		Navigation,
		Feedback,
	},
};
</script>
