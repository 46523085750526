<template>
	<div
		class="d-flex justify-center text-center align-center flex-column"
		id="login"
	>
		<div style="margin-bottom: 50px">
			<v-card
				width="300px"
				height="350px"
				class="d-flex flex-column justify-space-between pa-10"
			>
				<h2 class="text-h5 text-center font-weight-bold">Welcome</h2>
				<v-img
					alt="Barkeep"
					class="shrink ma-auto"
					contain
					src="@/assets/barkeep-large.png"
					width="200"
				/>
				<v-progress-circular
					indeterminate
					color="primary"
					class="ma-auto"
					v-if="loading"
				></v-progress-circular>
				<v-btn color="primary" @click="login" v-else>
					Log in with Microsoft
				</v-btn>
			</v-card>
		</div>
	</div>
</template>

<script>
import { getUser, signIn } from "@/auth";

export default {
	data() {
		return {
			loading: false,
		};
	},
	methods: {
		async login() {
			await signIn();
			this.$router.push("recommendations");
		},
	},
	async created() {},
	mounted() {
		const account = getUser();
		if (account) {
			this.$store.dispatch("setUser", {
				name: account.name,
				username: account.username,
			});
		}
	},
};
</script>

<style scoped>
#login {
	min-height: 100%;
	width: 100%;
}
</style>
